import React from 'react';
import { getSpacerClass } from '../utils/helpers';
import componentMapper from "./componentMapper";


const CustomComponent = ({ data }) => {
  const { content, className, spacer, _type } = data;

  const children = content?.map(block => {
    const Component = componentMapper(block._type);
    return (
      <Component parent={_type} key={block._key} data={block} />
    )
  });
  return (
    <div className={getSpacerClass(spacer)}>
      <div className="row">
        <div className={className}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default CustomComponent;
